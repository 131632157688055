body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

p {
  line-height: 1.4;
  margin: 0;
}

@font-face {
  font-family: "Kanji Stroke Order";
  src: url(fonts/KanjiStrokeOrders_v4.002.ttf) format("truetype");
}

@media print {
  .no-print {
    display: none;
  }
}

