header {
  background: #e26f6f;
  color: #fff;
  padding: 1.25em 1em;
}

h1 {
  margin: 0 0 .25em 0;
  text-transform: uppercase;
}

header a {
  color: #fff;
}

.input {
  background: #f3f3f3;
  margin-bottom: 2em;
  padding: 1em;
  width: calc(100% - 2em);
}

.output {
  font-family: "Kanji Stroke Order";
  margin: 0;
  padding: 0.5em;
}

.output.font-small {
  font-size: 1.5em;
}

.output.font-medium {
  font-size: 2em;
}

.output.font-large {
  font-size: 3em;
}

.output-line {
  break-inside: avoid;
  border: 1pt solid #ddd;
  line-height: 1;
  margin: 0 0 .25em 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.output-middle-line:before {
  content: "";
  border-top: 1pt solid #eee;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 0;
  z-index: -1;
}

.output-example {
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.1em;
}

.output-rotate .output-example {
  transform: rotate(-90deg);
}

.output-placeholder {
  font-style: italic;
  padding: 1em;
}

footer {
  border-top: 1px solid #ccc;
  margin-top: 2em;
  padding: 1em;
}
