.config {
  display: flex;
  padding: 1em;
}

.config-item {
  margin-right: 2em;
}

.config-item-label {
  margin-right: 4px;
}

.print {
  text-transform: uppercase;
}

@media (max-width: 1210px) {
  .config {
    flex-direction: column;
  }
  .config-item {
    margin-bottom: 1em;
  }
}
